<!-- tsx -->
<template>
  <div class="pageContent">
    <!-- 资产折合 -->
    <section class="mainAccout">
      <div class="centerBox">
        <div class="leftMain">
          <p class="title">总资产折合</p>
          <p class="num">
            <span style="font-size:32px">{{totalAssets | formatDigit}}</span>
          </p>
        </div>
        <div class="rightMain">
          <el-button class="mainBtn" type="primary" plain @click="goTransfer()">划转</el-button>
          <el-button class="mainBtn" type="primary" plain @click="goOutInt()">出入金管理</el-button>
          <el-button class="mainBtn" type="primary" plain @click="goInfo()">信息申报</el-button>
          <el-button class="mainBtn" type="primary" plain @click="goSign()">签约</el-button>
          <!-- <el-button class="mainBtn" type="primary" plain @click="goSignList()">解约</el-button> -->
          <el-button class="mainBtn" type="primary" plain @click="goSignList()">银行签约信息</el-button>
        </div>
      </div>
    </section>

    <!-- 账户tabs -->
    <section class="account" v-if="BaseMarkets.length != 0">
      <div class="wrapper">
        <div class="accTabs">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="(item,ind) in BaseMarkets" :key="ind" :label="item.accountName" :name="item.marketId">
              <!-- <p style="color: #9aa5b5;">{{item.accountName}}总资产</p> -->
              <div class="outBox">
                <div class="accBox">
                  <p class="items">账户资金: <span>{{(item.balance - item.frozenFunds) | formatDigit}}</span></p>
                  <p class="items">冻结资金: <span>{{item.frozenFunds | formatDigit}}</span></p>
                </div>
                <!-- <span class="huazhuan">划转</span> -->
                <el-button type="primary" class="huazhuan" plain @click="goCellTrans(item)">划转</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
          <!-- <span class="report" @click="reportShow()">财务记录</span> -->
        </div>
        <transfer-com  ref="transferCom" :activeFlag="activeName"/>
      </div>
    </section>

    <Footer/>

    <!-- 划转弹窗 -->
    <transfer-dialog ref="transferDialog"  @reLoad="showRepsss" />
  </div>
</template>

<script >
import transferCom from './component/transferCom'
import transferDialog from './component/transferDialog'
import Footer from '../../components/base/footer/footerZYQ'

export default {
components: {
  transferCom,
  transferDialog,
  Footer
},
  data() {
    return {
      activeName: this.$route.query.command ? this.$route.query.command : '',
      BaseMarkets:[],
      totalAssets:'0.00',
    }
  },
created() {},
mounted() {
  this.param_findAccountByUserId()
  // this.activeName = this.$route.query.command
  // console.log(typeof this.$route.query.command)
},
computed: {},
watch: {
  $route:{
    handler(val,oldval){
      this.activeName = val.query.command
    },
  }
},
filters: {
  formatDigit(e) {
    // console.log(e)
    if (e) {
      return Number(e).toFixed(2)
    } else {
      return Number(0).toFixed(2)
    }
  }
},
methods: {
  // 处理tabs
  // tabsMode(){
  //   console.log(this.$route.query.command, 'xxxxxxxxxxxx')
   
  // },
  showRepsss(val){
    // console.log(val,'shichang')
    this.$refs.transferCom.queryMyIntegralDetail(val)
  },
  // tabs 点击
  handleClick(tab, event){
    console.log(tab,event)
  },
  goTransfer() {
    this.$router.push('/transfer')
  },
  goInfo(){
    this.$router.push('/infoReporing')
  },
  goSign(){
    this.$router.push('/sign')
  },
  goOutInt(){
    this.$router.push('/StateFunding')
  },
  goSignList(){
    this.$router.push('/signList')
  },
  // 财务记录
  reportShow(){
    this.$router.push('/financialRecord')
  },
  goCellTrans(item){
    this.$refs.transferDialog.showDialog(item)
  },
  // 查询当前账户
  param_findAccountByUserId() {
    http.postFront(protocol.param_findAccountByUserId).then(response => {
      const { code, message, value } = response.data
      if (Number(code) === 0) {
        if (value) {
          this.BaseMarkets = value
          this.activeName = value[0].marketId
          let sum = 0
          for(let i = 0; i<this.BaseMarkets.length;i++){
            sum += (this.BaseMarkets[i].balance - this.BaseMarkets[i].frozenFunds)
          }
          if(sum == 0){
            this.totalAssets = '0.00'
          }else{
            this.totalAssets = sum
          }
        }else{
          this.BaseMarkets = []
        }
      }else{
        this.$EL_MESSAGE(message)
      }
    })
  },
},
}
</script>

<style lang='scss' scoped>
// @import url(); 引入公共css类
.mainAccout{
  height: 200px;
  background-color: #062786;
  display: flex;
  align-items: center;

}
.centerBox{
  width: 1200px;
  margin: 0 auto;
  height: 88px;
  display: flex;
  justify-content: space-between;
  .leftMain{
    .title{
      font-size: 14px;
      color: #788ddd;
    }
    .num{
      font-weight: 700;
      color: #ffffff;
    }
  }
  .rightMain{
    line-height: 88px;
    /deep/ .mainBtn{
      font-size: 16px!important;
    }
  }
}

.account{
  width: 100%;
  background: #fff;
  min-height: 850px;
  padding-top: 16px;
  margin-top: -26px;
  border-radius: 28px 28px 0 0;
  .wrapper{
    margin: 0 auto;
    width: 1200px;
  }
}
.accTabs{
  position: relative;
  .report{
    position: absolute;
    right: 0;
    top: 14px;
    font-size: 14px;
    color: #2483ff;
    cursor: pointer;
  }
  .outBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .huazhuan{
      margin:22px 10px 0 0;
      font-size: 16px;
      line-height: 30px;
      padding: 0 16px;
      border-radius: 4px;
      border: 1px solid #2483ff;
    }
  }
  .accBox{
    width: 400px;
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    .items{
      color: #9aa5b5;
      // display: inline-block;
      span{
        color: #1c242c;
        font-size: 24px;
        line-height: 22px;
        vertical-align: middle;
        margin-left: 15px;
      }
      margin-top: 8px;
      // line-height: 28px;
      align-items: center;
    }

  }
  
}

// 组件内修改样式
/deep/ .el-tabs__item{
  cursor: pointer;
  // color: #1c242c;
  font-size: 16px!important;
}
</style>
