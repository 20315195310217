<!-- tsx -->
<template>
  <div id="package">
    <el-dialog
      title="划转"
      v-dialogDrag
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <!-- 业务区 -->
      <section class="mainTrans">
        <div class="top wrapper">
          <p class="fangxiang">划转方向</p> 
          <div class="toFrom">
            <div class="leftTo">
              <span>从</span>
              <span>至</span>
            </div>
            <div class="centerFrom">
              <el-input v-model="from" placeholder="划转金额" style="width: 100%" class="itemsec" :disabled="disableSec"></el-input>
              <!-- <el-select v-model="from" placeholder="请选择账户" @change="change" style="width: 100%" class="itemsec" :disabled="disableSec">
                <el-option v-for="(item,index) in BaseMarkets" :key="index" :value="item.userAccountid" :label="item.accountName"></el-option>
              </el-select> -->
              <el-select v-model="to" placeholder="请选择账户" @change="changes" style="width: 100%"  class="itemsec">
                <el-option v-for="(item,index) in BaseMarkets" :key="index" :value="item.userAccountid" :label="item.accountName"></el-option>
              </el-select>
            </div>
          </div>
          <div class="money wrapper">
            <p class="fangxiang">划转金额</p> 
            <el-input v-model="number" type="number" placeholder="划转金额"></el-input>
            <p class="contentTit">账户资金 : {{ (selectMarket.balance - selectMarket.frozenFunds) | formatDigit}}</p>
            <p class="contentTit">账户冻结资金 : {{ selectMarket.frozenFunds | formatDigit}}</p>
            <!-- <el-button class="submit" :disabled="disable" @click="submit()">提交</el-button> -->
          </div>
        </div>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="disable" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script >
export default {
  components: {},
  data() {
    return {
      BaseMarkets: [],
      dialogVisible: false,
      selectMarket: {
        'accountName': '',
        'balance': 0.00,
        'frozenFunds': 0.00,
        'userAccountid': 0,
        'userId': 0
      },
      from: null,
      to: null,
      asd: null,
      number: '',
      disable: false,
      disableSec:true,
      targetId: '',
      marketId: ''
    };
  },
  filters: {
    formatDigit(e) {
      if (e) {
        return Number(e).toFixed(2)
      } else {
        return Number(0).toFixed(2)
      }
    }
  },
  created() {},
  mounted() {
    this.findAccountByUserId()
  },
  computed: {},
  watch: {},
  methods: {
    showDialog(item) {
      this.dialogVisible = true;
      // userAccountid
      this.to = null
      this.number = ''
      this.targetId = item.userAccountid
      this.marketId = item.marketId
      this.selectMarket = {
        'accountName': '',
        'balance': 0.00,
        'frozenFunds': 0.00,
        'userAccountid': 0,
        'userId': 0
      }
      this.findAccountByUserId(item.userAccountid)
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 资金去向
    changes(selOption) {
     console.log(selOption)
    },
    // 资金来源
    change(selOption) {
      this.asd = selOption
      console.log(selOption, 999)
      this.selectMarket = {
        'accountName': '',
        'balance': 0.00,
        'frozenFunds': 0.00,
        'userAccountid': 0,
        'userId': 0
      }
      for (let i = 0; i < this.BaseMarkets.length; i++) {
        const element = this.BaseMarkets[i]
        if (element.userAccountid === selOption) {
          this.selectMarket = element
        }
      }
    },
    submit() {
      if (!this.targetId) {
       this.$EL_MESSAGE('请选择划转账户')
       return
      }
      if (!this.to) {
        this.$EL_MESSAGE('请选择划转账户')
        return
      }
       if (this.targetId === this.to) {
        this.$EL_MESSAGE('两次选择账户相同')
        return
      }
      if (!this.number) {
        this.$EL_MESSAGE('划转资金未填写')
        return
      }
      if (Number(this.number) <= 0) {
        this.$EL_MESSAGE('划转资金填写错误')
        return
      }
      if (Number(this.number) > (Number(this.selectMarket.balance) - Number(this.selectMarket.frozenFunds))) {
        this.$EL_MESSAGE('划转资金超额')
        return
      }
      this.transferFundsOwn()
    },
    // 资金划转(多市场间划转)
    transferFundsOwn() {
      console.log(this.marketId,'123456')
      var that = this
      this.disable = true
      protocol.param_transferFundsOwn.param.amount = this.number
      protocol.param_transferFundsOwn.param.fromUserAccountId = this.targetId
      protocol.param_transferFundsOwn.param.toUserAccountId = this.to
      http.postFront(protocol.param_transferFundsOwn).then(response => {
        const { code, message, value } = response.data
        this.$EL_MESSAGE(message)
        this.disable = false
        if (code == 0) {
          this.$EL_MESSAGE(message)
          this.dialogVisible = false
          this.$emit('reLoad', this.marketId)
          this.to = null
          this.number = ''
          // this.param_findAccountByUserId()
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    // 资金划转市场列表
    findAccountByUserId(userAccountid) {
     http.postFront(protocol.param_findAccountByUserId).then(response => {
       const { code, message, value } = response.data
      if (Number(code) === 0) {
        if (value) {
          this.BaseMarkets = value
          this.BaseMarkets.forEach(element => {
            if(userAccountid == element.userAccountid){
              this.selectMarket = element
              this.from = element.accountName
            }
          })
        } else {
          this.BaseMarkets
        }
      }else{
        this.$EL_MESSAGE(message)
      }
    })
    },
  },
};
</script>

<style lang='scss' scoped>
// @import url(); 引入公共css类
.mainTrans{
  margin: 0 auto;
  // width: 1000px;
  // border: 1px solid #e6ecf2;
  background-color: #fff;
  border-radius: 2px;
  // min-height: 440px;
  color: #1c242c;
  padding: 32px 0;
}
.wrapper{
  width: 520px;
  margin: 0 auto;
}
.top{
  .toFrom{
    border: 1px solid #dfe2e7;
    border-radius: 2px;
    background: #fff;
    height: 114px;
    display: flex;

    .leftTo{
      width: 50px;
      display: flex;
      flex-direction: column;
      margin: 25px 0 0 30px;
      span{
        margin-bottom: 25px;
      }
    }
    .centerFrom{
      width: 80%;
      .itemsec{
        margin-top: 12px;
      }
     
    }

  }
}
.fangxiang{
  color: #495666;
  font-size: 12px;
  margin-bottom: 8px;
}
.money{
  margin-top: 30px;
  .contentTit{
    line-height: 18px;
    font-size: 12px;
    margin-top: 8px;
    color: #9aa5b5;
  }
  .submit{
    background-color: #e5ebf5;
    color: #9aa5b5;
    width: 200px;
    margin-top: 36px;
    height: 40px;
    border-radius: 2px;
    font-size: 16px;
  }
}
/deep/ .el-dialog__body{
  padding: 0!important;
}
// /deep/ .el-dialog__header{
//   background-color: rgba(242,246,250,.5)!important;
// }
// /deep/ .el-dialog__footer{
//   background-color: rgba(242,246,250,.5)!important;
// }
</style>
