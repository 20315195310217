<!-- tsx -->
<template>
  <div class="financialRecord">
    <!-- <section class="toHeader">
      <div class="rightBox">
        <div class="right" v-if="activeFlag != 18">
          <span v-for="(item,index) in dateArr" :key="index" :class="{'activeColor': item.active}" @click="showRep(item.id)">{{item.title}}</span>
        </div>
        <div class="right" v-else>
          <span v-for="(item,index) in lstDateArr" :key="index" :class="{'activeColor': item.active}" @click="showRep(item.id)">{{item.title}}</span>
        </div>
        <el-date-picker
          v-model="selectData"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyyMMdd"
        >
        </el-date-picker>
      </div>
    </section> -->

    <section class="tables">
      <div class="tableHeader">
        <!-- <span style="cursor: pointer">{{typeRep}}</span> -->
        <!-- <span style="cursor: pointer" v-if="activeFlag == '18'">{{typeRep}}</span> -->
        <section class="toHeader">
          <div class="rightBox">
            <div class="right" v-if="activeFlag != 18">
              <span
                v-for="(item, index) in dateArr"
                :key="index"
                :class="{ activeColor: item.active }"
                @click="showRep(item.id)"
                >{{ item.title }}</span
              >
            </div>
            <div class="right" v-else>
              <span
                v-for="(item, index) in lstDateArr"
                :key="index"
                :class="{ activeColor: item.active }"
                @click="showRep(item.id)"
                >{{ item.title }}</span
              >
            </div>
            <el-date-picker
              v-model="selectData"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyyMMdd"
            >
            </el-date-picker>
          </div>
        </section>
      </div>
      <el-table :data="tableData" style="width: 100%; min-height: 400px;" :header-cell-style="{background:'#161D43',color: '#FFFFFF'}">
        <template slot="empty">
          <div class="no-datas1">
            <div
              class="no-data-img"
              style="margin: 100px auto 0px; width: 120px; height: 100px;"
            >
              <img
                src="../../../assets/images/no_data.png"
                alt=""
                style="width: 100%;"
              />
            </div>
            <div
              class="no-data"
              style="margin-top: -25px !important; text-align: center;"
            >
              {{ $t("message.No_data") }}
            </div>
          </div>
        </template>
        <el-table-column prop="occurTime" label="时间" width="180">
          <template slot-scope="scope">{{
            scope.row.occurTime | dateFormate
          }}</template>
        </el-table-column>
        <el-table-column
          prop="bizName"
          label="类型"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="金额"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="marketName"
          label="市场"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="note"
          label="流水号"
          v-if="isRepors"
        ></el-table-column>
        <el-table-column
          prop="flowId"
          label="流水号"
          v-if="!isRepors"
        ></el-table-column>
      </el-table>

      <el-pagination
        :current-page="pageData.currentPage"
        :page-sizes="pageData.pageSizes"
        :page-size="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </section>
  </div>
</template>

<script>
export default {
  props: ["activeFlag"],
  components: {},
  data() {
    return {
      activeName: "first",
      selectNav: "0",
      tableData: [],
      BaseMarkets: [],
      pageData: {
        total: 0,
        pageSizes: [5, 10, 20],
        pageSize: 10,
        currentPage: 1
      },
      selectData: "",
      dateArr: [
        { id: 0, title: "交易流水", active: true },
        { id: 1, title: "入金记录", active: true },
        { id: 2, title: "出金记录", active: true }
      ],
      lstDateArr: [
        { id: 0, title: "交易流水", active: false },
        { id: 1, title: "入金记录", active: false },
        { id: 2, title: "出金记录", active: false }
      ],
      typeRep: this.activeFlag == "18" ? "交易流水" : "入金记录",
      isRepors: false
    };
  },
  created() {},
  mounted() {
    // this.findAccountByUserId()
    // this.queryMyIntegralDetail()
    // console.log(this.$route.query.command,'xxxxxxx')
    // if(this.$route.query.command == '预售交易账户'){
    this.queryMyIntegralDetail(sessionStorage.getItem("marketId_w"));
    // }else{
    //   this.queryMyIntegralDetail(18)
    // }
  },
  computed: {},
  watch: {
    selectData(val, oldVal) {
      this.pageData = {
        total: 10,
        pageSizes: [5, 10, 20],
        pageSize: 10,
        currentPage: 1
      };
      this.tableData = [];
      this.queryMyIntegralDetail();
    },
    selectNav(val, oldVal) {
      console.log(val, "xxxx");
      if (val == 1) {
        this.isRepors = true;
      } else {
        this.isRepors = false;
      }
      this.pageData = {
        total: 10,
        pageSizes: [5, 10, 20],
        pageSize: 10,
        currentPage: 1
      };
      this.tableData = [];
      this.queryMyIntegralDetail();
    },
    // $route:{
    //   handler(val,oldval){
    //     if(this.$route.query.command == '预售交易账户'){
    //       this.queryMyIntegralDetail(28)
    //     }else{
    //       this.queryMyIntegralDetail(18)
    //     }
    //   },
    // },
    activeFlag: {
      handler(val, oldVal) {
        console.log("activeFlag", this.activeFlag);
        // if(val == '18'){
        //   this.selectNav = '2'
        // } else {
        //   this.selectNav = '0'
        // }
        this.selectNav = "0";

        this.queryMyIntegralDetail(val);
        this.lstDateArr[0].active = true;
        this.dateArr[0].active = true;
        this.lstDateArr.forEach(element => {
          if (element.id != "0") {
            element.active = false;
          }
        });
        this.dateArr.forEach(element => {
          if (element.id != "0") {
            element.active = false;
          }
        });
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.queryMyIntegralDetail();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.queryMyIntegralDetail();
    },
    showRep(ind) {
      console.log(ind, 9999);
      // 点击激活添加的样式
      this.dateArr.forEach(element => {
        element.active = false;
      });
      this.dateArr.forEach(element => {
        if (ind == element.id) {
          element.active = true;
        }
      });
      this.lstDateArr.forEach(element => {
        if (ind == element.id) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
      // this.lstDateArr.forEach(element => {
      //   if(ind == element.id){
      //     element.active = true
      //   }
      // })
      // 赋值
      this.selectNav = ind;
      if (ind == 0) {
        this.typeRep = "入金记录";
        //101出金 102入金 null 其它
        protocolFwd.param_queryMyIntegralDetailHis.param.typeCode = null;
      } else if (ind == 1) {
        this.typeRep = "出金记录";
        protocolFwd.param_queryMyIntegralDetailHis.param.typeCode = 101;
      } else {
        this.typeRep = "划转记录";
        protocolFwd.param_queryMyIntegralDetailHis.param.typeCode = 102;
      }
    },
    goBack() {
      this.$router.push("/conditionOfAssets");
    },
    // 资金划转市场列表
    findAccountByUserId() {
      console.log(this.selectNav, "this.selectNav");
      http.postFront(protocol.param_findAccountByUserId).then(response => {
        const { code, message, value } = response.data;
        if (code == "0") {
          if (value) {
            this.BaseMarkets = value;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    queryMyIntegralDetail(marketId) {
      console.log(marketId, "zhehsiwo");
      let protocolUrlData = {};
      if (this.selectData) {
        // 历史
        protocolUrlData = protocolFwd.param_queryMyIntegralDetailHis;
        protocolUrlData.param.starTime = this.selectData[0];
        protocolUrlData.param.endTime = this.selectData[1];
      } else {
        // 当前
        protocolUrlData = protocolFwd.param_queryMyIntegralDetail;
      }

      if (this.selectNav == "0") {
        console.log("666666");
        // 其它
        protocolUrlData.param.typeCode = null;
      } else if (this.selectNav == "1") {
        // 出金
        protocolUrlData.param.typeCode = 101;
      } else {
        // 入金
        protocolUrlData.param.typeCode = 102;
      }
      if (marketId) {
        // this.selectNav = '2'
        // this.typeRep = '划转记录'
        this.dateArr.forEach(element => {
          element.active = false;
        });
        this.dateArr.forEach(element => {
          if (this.selectNav == element.id) {
            element.active = true;
          }
        });
        protocolUrlData.param.marketId = marketId;
      } else {
        protocolUrlData.param.marketId = this.activeFlag;
      }
      protocolUrlData.param.queryType = "1";
      protocolUrlData.param.page = this.pageData.currentPage - 1;
      protocolUrlData.param.size = this.pageData.pageSize;
      http.postFront(protocolUrlData).then(response => {
        const { code, message, value } = response.data;
        if (code == "0") {
          if (value && code == "0") {
            this.pageData.total = value.totalElements;
            const arr = value.content;
            // 处理千分位分隔符，和小数点后两位
            arr.forEach(element => {
              element.amount = util.formatNum(element.amount);
              // console.log(util.formatNum(element.amount))
            });
            this.tableData = arr;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// @import url(); 引入公共css类
.financialRecord {
  width: 100%;
  // height: 80px;
  // display: flex;
  margin-top: 20px;
  // margin-bottom: 24px;
  // background-color: #1b2945;
  // justify-content: center;
  // align-items: flex-end;
  // overflow: hidden;
}
.toHeader {
  height: 56px;
  line-height: 56px;
  width: 1200px;
  margin: 0 auto;
  // overflow: hidden;
  // .left{
  //   float: left;
  //   font-size: 16px;
  //   color: #fff;
  //   margin-right: 100px;
  //   span{
  //     line-height: 80px;
  //   }
  // }
  .right {
    display: inline-block;
    // float: left;
    width: 300px;
    height: 48px;
    // margin-left: 30px;
    span {
      font-weight: 300;
      font-size: 14px;
      color: #333333;
      margin-right: 40px;
      cursor: pointer;
      display: inline-block;
      border-bottom: 3px solid transparent;
    }
  }
}
.tables {
  // width: 1200px;
  // border: 1px solid #e5ebf5;
  margin: 10px auto;
  .tableHeader {
    // height: 48px;
    line-height: 48px;
    color: #4b5666;
    padding: 0 24px;
    background-color: #F2F6FA;
    border-bottom: 1px solid #e6ecf2;
  }
}
.el-tabs__item {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.el-pagination {
  text-align: center;
  margin: 15px 0;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: none !important;
}
.el-tabs__header {
  line-height: 80px !important;
  margin: 0 !important;
}
.activeColor {
  color: #1989fa !important;
}
</style>
